import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Span, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				CityCruiser Rentals
			</title>
			<meta name={"description"} content={"У CityCruiser Rentals ми пропонуємо більше, ніж просто прокат велосипедів – ми пропонуємо повний досвід дослідження міста."} />
			<meta property={"og:title"} content={"CityCruiser Rentals"} />
			<meta property={"og:description"} content={"У CityCruiser Rentals ми пропонуємо більше, ніж просто прокат велосипедів – ми пропонуємо повний досвід дослідження міста."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				CityCruiser Rentals
			</Override>
		</Components.Header>
		<Components.Hero background="linear-gradient(0deg,rgba(4, 16, 41, 0.74) 0%,rgba(4, 16, 41, 0.79) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-2.jpg?v=2024-06-11T09:36:20.671Z) center/cover repeat scroll padding-box">
			<Override slot="link" display="none" />
			<Override slot="text">
				Що ми пропонуємо
			</Override>
			<Override slot="text1" font="--lead">
				У CityCruiser Rentals ми пропонуємо більше, ніж просто прокат велосипедів – ми пропонуємо повний досвід дослідження міста. Наші послуги призначені як для звичайних дослідників міста, так і для відданих міських авантюристів. Кожна оренда гарантує якість, зручність і турботу, щоб ваша подорож містом була приємною та незабутньою.
			</Override>
		</Components.Hero>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-1.jpg?v=2024-06-11T09:36:20.671Z"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-1.jpg?v=2024-06-11T09%3A36%3A20.671Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-1.jpg?v=2024-06-11T09%3A36%3A20.671Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-1.jpg?v=2024-06-11T09%3A36%3A20.671Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-1.jpg?v=2024-06-11T09%3A36%3A20.671Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-1.jpg?v=2024-06-11T09%3A36%3A20.671Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-1.jpg?v=2024-06-11T09%3A36%3A20.671Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-1.jpg?v=2024-06-11T09%3A36%3A20.671Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="#564aec" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						Ми пропонуємо широкий асортимент міських круїзерів, які ідеально підходять для різних міських місцевостей і вподобань водія
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="--headline3">
						Наш винятковий автопарк
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
						Кожен велосипед оснащений:{"\n"}
						<br />
						Преміум Комфорт: Ергономічні сидіння та зручні рами.
						<br />
						<br />
						Безпека перш за все: Оснащений ліхтарями, дзвінками та світловідбиваючими елементами для безпечної навігації містом.
						<br />
						<br />
						{"\n"}Настроювані параметри: Регульовані сидіння та кермо відповідно до різного росту та вподобань водія.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-4.jpg?v=2024-06-11T09:36:20.683Z"
						display="block"
						width="100%"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						max-height="400px"
						srcSet="https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-4.jpg?v=2024-06-11T09%3A36%3A20.683Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-4.jpg?v=2024-06-11T09%3A36%3A20.683Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-4.jpg?v=2024-06-11T09%3A36%3A20.683Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-4.jpg?v=2024-06-11T09%3A36%3A20.683Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-4.jpg?v=2024-06-11T09%3A36%3A20.683Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-4.jpg?v=2024-06-11T09%3A36%3A20.683Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-4.jpg?v=2024-06-11T09%3A36%3A20.683Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 90px 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
					order="-1"
				>
					<Text margin="0px 0px 10px 0px" color="#564aec" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						Ми розуміємо, що потреби кожного різні, тому пропонуємо індивідуальні плани оренди
					</Text>
					<Text margin="2rem 0px 0 0px" color="--dark" font="--headline3">
						Комплексні плани оренди
					</Text>
					<Text margin="1rem 0px 0px 0px" font="--base" color="--darkL2">
						Погодинна оренда: ідеально підходить для швидких поїздок містом або неспішних поїздок парком.{"\n"}
						<br />
						<br />
						Оренда на цілий день: ідеально підходить для цілого дня дослідження у вашому власному темпі.{"\n"}
						<br />
						<br />
						Довгостроковий лізинг: Підходить для тих, кому потрібен надійний варіант пересування.
						<br />
						<br />
						{"\n"}Кожен план включає:
Гнучкі умови: Легке продовження та відсутність прихованих комісій.
						<br />
						<br />
						{"\n"}Ціни «все включено»:  комплексні тарифи, які охоплюють технічне обслуговування, засоби безпеки та підтримку.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09:36:20.679Z"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09%3A36%3A20.679Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09%3A36%3A20.679Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09%3A36%3A20.679Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09%3A36%3A20.679Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09%3A36%3A20.679Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09%3A36%3A20.679Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deaa8d4a0c002030449f/images/1-3.jpg?v=2024-06-11T09%3A36%3A20.679Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="#564aec" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						Щоб покращити ваші враження від катання, ми також пропонуємо різноманітні аксесуари та спорядження для оренди
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="--headline3">
						Аксесуари та спорядження
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
						{" "}Шоломи: необхідні для безпеки, доступні в різних розмірах.{"\n"}
						<br />
						<br />
						Замки: Високоякісні замки для захисту велосипеда під час зупинок.{"\n"}
						<br />
						<br />
						Карти та путівники: Підібрані ресурси, які допоможуть вам знайти приховані перлини міста.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/6666deaa8d4a0c002030449f/images/2-1.jpg?v=2024-06-11T09:36:20.680Z) center center/cover no-repeat"
			min-height="60vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Відкрийте більше з нами
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					max-width="60%"
				>
					Цікаво, що ще ми пропонуємо? Завітайте до нас, щоб ознайомитися з усім спектром наших послуг і знайти ідеальний план для ваших пригод містом. Ми тут, щоб зробити вашу оренду максимально приємною та безпроблемною.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Нехай подорож починається!
					</Span>
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});